<template>
	<div class="searchbox">
		<div class="searchbox__cities search-city">
			<div class="search-city__labels">
				<span class="search-city__label">
					<tk-svg type="location" />
					<span class="search-city__label-txt">Прибытие</span>
				</span>
			</div>

			<tk-select
				@focus="getDefaultCities"
				v-model="filter.city"
				filterable
				remote
				v-cancel-read-only
				:remote-method="querySearch"
				placeholder="Куда отправляетесь?"
				popper-class="autocomplete-result"
				class="search-select search-city__select"
			>
				<template #empty>
					<div class="search-city__result">
						<span class="search-city__empty-result">
							Мы не нашли ни одного города по введённому тексту в поле поиска.
						</span>
					</div>
				</template>

				<div v-if="favorites?.length" class="search-city__result">
					<template v-for="group in favorites" :key="group.key">
						<div v-if="group.values?.length" class="search-city__group-result">
							<span class="search-city__group-title">
								{{ _titles[group.key] }}
							</span>
							<tk-option
								v-for="item in group.values"
								:key="item.city_fias_id"
								:label="item.city_ru"
								:value="item.city_fias_id"
							>
								<span class="search-city__result-item">
									{{ item.city_ru }}
								</span>
							</tk-option>
						</div>
					</template>
				</div>
				<div v-else class="search-city__result">
					<tk-option
						v-for="city in cities"
						:key="city.city_fias_id"
						:label="city.value"
						:value="city.city_fias_id"
					>
						<span
							class="search-city__result-item search-city__result-item--icon"
						>
							{{ city.value }}
						</span>
					</tk-option>
				</div>
			</tk-select>
		</div>

		<span class="searchbox__line" />

		<div class="searchbox__datepicker datepicker">
			<div class="datepicker__labels" @click.prevent="openDatepicker()">
				<span class="datepicker__label">
					<tk-svg type="calendar" />
					<span class="datepicker__label-txt">Прибытие</span>
				</span>

				<span class="datepicker__range-separator" />

				<span class="datepicker__label datepicker__label--end">
					<span class="datepicker__label-txt">Выезд</span>
				</span>
			</div>

			<div class="datepicker__labels-mobile" @click.prevent="openDatepicker()">
				<span class="datepicker__label">
					<tk-svg type="calendar" />
					<span class="datepicker__label-txt">{{
						date[0] ? getDateInFormat(date[0]) : 'Прибытие'
					}}</span>
				</span>

				<span class="datepicker__range-separator" />

				<span class="datepicker__label datepicker__label--end">
					<span class="datepicker__label-txt">{{
						date[1] ? getDateInFormat(date[1]) : 'Выезд'
					}}</span>
				</span>
			</div>

			<tk-datepicker
				class="datepicker__content"
				popper-class="date-time-picker"
				prefix-icon="none"
				format="DD.MM.YYYY"
				v-model="date"
				value-format="YYYY-MM-DD"
				type="daterange"
				start-placeholder="Когда?"
				end-placeholder="Когда?"
				:disabled-date="isDateDisabled"
				:clearable="false"
				:teleported="false"
				ref="datepicker"
			/>
		</div>

		<span class="searchbox__line" />

		<div class="searchbox__guests search-guests">
			<tk-drop-down>
				<template #toggle>
					<div class="search-guests__toggle input-search">
						<span class="input-search__label">
							<tk-svg type="users" />
							<span class="input-search__text"
								><span>Количество </span>гостей:</span
							>
						</span>
						<input
							type="number"
							readonly
							placeholder="Кто едет?"
							class="input-search__content"
							:value="countGuests"
						/>
					</div>
				</template>
				<template #content>
					<Guests
						class="search-guests__content"
						@update="
							($event) => {
								filter = { ...filter, ...$event }
							}
						"
						:children="filter.children"
						:guests="filter.guests"
					/>
				</template>
			</tk-drop-down>
		</div>

		<tk-button :disabled="isDisabled" @click="submit" class="searchbox__submit">
			Искать
		</tk-button>
	</div>
</template>

<script>
import Guests from '@/components/Guests'
import { objToArr } from '@/utils/normalizeData'
import { isDateInBookingInterval } from '@/utils/dateTime'
import { getDateInFormat } from '@/utils/dateTime'

export default {
	name: 'SearchPanel',
	components: { Guests },
  inject: ["metricsService"],
	watch: {
		date(val) {
			if (val?.length) {
				this.filter.date_from = val[0]
				this.filter.date_to = val[1]
			}
		}
	},
	data() {
		return {
			favorites: [],
			cities: [],
			city: '',
			date: [],
			fias: '',
			filter: {
				children: 0,
				guests: 2,
				city: '',
				date_from: null,
				date_to: null
			}
		}
	},
	computed: {
		_titles() {
			return {
				user_cities: 'Вы искали ранее',
				favorite_cities: 'Популярные запросы'
			}
		},
		isDisabled() {
			const _fields = ['guests', 'city', 'date_from', 'date_to']
			return !!_fields.find((itm) => !this.filter[itm])
		},
		countGuests() {
			return this.filter.children + this.filter.guests || null
		}
	},
	methods: {
		async submit() {
			let query
			if (this.$route.name === 'Places') {
				query = this.$route.query
				Object.assign(query, this.filter)
				query.f = undefined
			} else {
				query = this.filter
			}

			if (this.fias.length > 0) {
				const city = await this.$api.address.city({ fias: this.fias })
				if (this.filter.city === city) {
					query.city = this.fias
				}
			}

      this.metricsService.sendEvent("guest_search")
			this.$router.push({ path: '/places', query: { ...query, f: 0 } })
		},
		async getDefaultCities() {
			if (this.favorites.length) {
				return
			}

			this.cities = []

			const response = await this.$api.search.getFavorite()

			if (response?.status) {
				this.favorites = objToArr(response.response, 'key', 'values')
			}
		},
		async querySearch(payload) {
			if (payload.length < 3) {
				return
			}
			this.loading = true
			this.favorites = []

			const response = await this.$api.search.getCities({ find: payload })

			if (response?.status) {
				this.loading = false
				this.cities = [...response.response]
			}
		},
		isDateDisabled(date) {
			return !isDateInBookingInterval(date)
		},
		openDatepicker() {
			this.$refs.datepicker.handleOpen()
		},
		getDateInFormat
	},
	directives: {
		cancelReadOnly(el) {
			const input = el.querySelector('.el-input__inner')
			input.removeAttribute('readonly')
		}
	},
	async beforeMount() {
		const query = this.$route.query
		if (query.children) this.filter.children = parseInt(query.children)
		if (query.guests) this.filter.guests = parseInt(query.guests)
		if (query.city) {
			this.fias = query.city
			this.filter.city = await this.$api.address.city({ fias: query.city })
		}
		if (query.date_from) {
			this.filter.date_from = query.date_from
			this.date[0] = query.date_from
		}
		if (query.date_to) {
			this.filter.date_to = query.date_to
			this.date[1] = query.date_to
		}
	}
}
</script>

<style lang="sass">
.searchbox
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  max-width: 915px
  height: 90px
  padding: 25px 20px
  background-color: #FFFFFF
  box-shadow: 0px 13px 24px rgba(0, 0, 0, 0.05)
  border-radius: 10px
  box-sizing: border-box

  &__datepicker
    .el-popper
      left: 50% !important
      transform: translateX(-50%) !important
      top: 70px !important
    .el-popper__arrow
      display: none !important

  &__line
    display: block
    margin: 0 25px
    width: 1px
    height: 100%
    background-color: #E1E1E1

  &__submit
    width: 125px
    height: 42px

  @media screen and (max-width: 1180px)
    &__submit
      min-width: 80px

  @media screen and (max-width: 920px)
    height: 50px
    padding: 5px

    &__line
      margin: 0 10px

  @media screen and (max-width: 700px)
    &__datepicker
    .el-popper
      width: 100%

  @media screen and (max-width: 500px)
    flex-direction: column
    height: auto
    gap: 10px
    align-items: flex-start
    padding: 20px
    background-color: rgba(255, 255, 255, .7)
    & > label,
    & > div
      width: 100%
      max-width: 100%
      padding: 10px
      background-color: white
      min-height: 39px
      align-items: center
      border-radius: 10px
    &__submit
      margin-left: 5px
      width: 100%
      margin: 10px 0 0 0
    &__datepicker
    .el-popper
      top: 270px !important
.search-city
  width: 100%
  max-width: 260px

  &__labels
    display: flex
    justify-content: space-between

  &__label
    width: 48%

    svg
      vertical-align: bottom

  &__label-txt
    margin-left: 8px
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 14px
    color: #BEBEBE

  &__select
    padding-left: 10px

  &__result
    padding: 15px 0
    box-sizing: border-box

  &__result-item
    padding: 0 30px
    font-style: normal
    font-weight: 400
    font-size: 18px
    line-height: 21px
    color: #3B3F47

    &--icon
      position: relative
      padding-left: 15px
      margin-left: 25px

      &:before
        content: ''
        position: absolute
        top: 0
        bottom: 0
        left: 0
        width: 8px
        height: 10px
        margin: auto 0
        background: url('~@/assets/img/svg/views/home/search-result.svg')

  &__group-result
    margin-bottom: 20px

  &__group-title
    display: inline-block
    padding-left: 35px
    margin-bottom: 10px
    font-weight: 400
    font-size: 14px
    line-height: 17px
    color: #000000
    opacity: 0.2

  &__empty-result
    display: inline-block
    padding: 0 30px
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 17px
    color: #E93030

  @media screen and (max-width: 780px)
    display: flex
    align-items: center
    justify-content: space-between
    width: auto
    &__label svg
      vertical-align: middle

    &__select
      padding-left: 0

    &__label-txt
      display: none

.datepicker
  &__labels, &__labels-mobile
    align-items: flex-end
    justify-content: space-between

  &__labels
    display: flex

  &__labels-mobile
    display: none

  &__label
    width: 48%

    svg
      width: 15px
      height: 15px
      vertical-align: bottom

  &__label-txt
    margin-left: 8px
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 14px
    color: #BEBEBE
    width: 100%

  &__range-separator
    width: 18px
    height: 1px
    margin-left: -18px
    background-color: #E1E1E1

  @media screen and (max-width: 780px)
    &__content
      display: none !important

    &__label
      width: 15px
      display: flex
      justify-content: center

      &--end
        display: none

    &__range-separator
      display: none

    &__label-txt
      display: none
  @media screen and (max-width: 500px)
    display: flex
    &__labels
      align-items: center
      display: none
    &__labels-mobile
      display: flex
    &__label
      width: 100%
      display: flex
      align-items: center
      &--end
        &::before
          content: ''
          display: block
          width: 1px
          height: 15px
          margin-left: 10px
          background-color: #2A3043
    &__content
      display: block
    &__label-txt
      display: block
      font-size: 16px
      color: #2A3043
.search-guests
  max-width: 140px
  display: flex
  & > div
    width: 100%
  &__content
    margin-top: 15px
    margin-left: -20%
    @media screen and (max-width: 500px)
      margin-left: 70px
</style>

<style lang="sass">
.input-search
  cursor: pointer
  &__label
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 14px
    color: #BEBEBE

    .iconm
      font-size: 12px

  &__text
    display: inline-block
    margin-left: 10px

  &__content
    padding: 0
    margin-left: 25px
    border: 0
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 16px
    color: #2A3043
    background-color: transparent
    cursor: pointer

    &::-moz-placeholder
      color: #2A3043
    &::-webkit-input-placeholder
      color: #2A3043
    &::-ms-input-placeholder
      color: #2A3043
    &::placeholder
      color: #2A3043

    &:hover,
    &:focus,
    &:active
      outline: 0

  @media screen and (max-width: 780px)
    &__content
      display: none
    &__text
      display: none
  @media screen and (max-width: 500px)
    display: flex
    &__label
      display: flex
      align-items: center
    &__text
      display: block
      color: #2A3043
      font-size: 16px
      margin-left: 8px
      &::first-letter
        text-transform: uppercase
      span
        display: none
    &__content
      display: block
      margin-left: 5px
      max-width: 30px
</style>

<style lang="sass">
.autocomplete-result
  --el-color-primary: #5F5350

  .el-select-dropdown__item
    padding: 0
    width: 100%

.search-select
  width: 100%
  height: 16px
  .el-input
    &__wrapper
      padding: 0
      border: 0
      box-shadow: none !important
      &.is-focus
        box-shadow: none !important
    &__inner
      width: 100%
      height: 16px
      padding-left: 10px
      font-style: normal
      font-weight: 400
      font-size: 16px
      line-height: 16px
      color: #2A3043
      box-sizing: border-box
      &::-moz-placeholder
        color: #2A3043
      &::-webkit-input-placeholder
        color: #2A3043
      &::-ms-input-placeholder
        color: #2A3043
      &::placeholder
        color: #2A3043

  .el-input.is-focus .el-input__wrapper
    box-shadow: none !important
</style>
