<template>
	<div class="count-guests">
		<div class="count-guests__container">
			<p class="count-guests__text">
				Взрослые
				<span class="count-guests__sub-text">Возраст от 13</span>
			</p>

			<div class="count-guests__values">
				<button
					type="button"
					:disabled="values.guests < 1"
					@click="minus('guests')"
					class="btn count-guests__btn"
				>
					<tk-svg type="btn-minus" />
				</button>
				<input
					v-model="values.guests"
					readonly
					type="text"
					class="count-guests__input"
				/>
				<button
					type="button"
					:disabled="values.guests > 10"
					@click="plus('guests')"
					class="btn count-guests__btn"
				>
					<tk-svg type="btn-plus" />
				</button>
			</div>
		</div>
		<div class="count-guests__container">
			<p class="count-guests__text">
				Дети
				<span class="count-guests__sub-text">Возраст 0-12</span>
			</p>

			<div class="count-guests__values">
				<button
					type="button"
					:disabled="values.children < 1"
					@click="minus('children')"
					class="btn count-guests__btn"
				>
					<tk-svg type="btn-minus" />
				</button>
				<input
					v-model="values.children"
					readonly
					type="number"
					class="count-guests__input"
				/>
				<button
					type="button"
					:disabled="values.children > 10"
					@click="plus('children')"
					class="btn count-guests__btn"
				>
					<tk-svg type="btn-plus" />
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		guests: {
			type: [String, Number],
			default: null
		},
		children: {
			type: [String, Number],
			default: null
		}
	},
	watch: {
		values: {
			handler() {
				this.$emit('update', this.values)
			},
			deep: true
		}
	},
	data() {
		return {
			values: {
				guests: 0,
				children: 0
			}
		}
	},
	created() {
		if (this.guests) {
			this.values.guests = this.guests
		}

		if (this.children) {
			this.values.children = this.children
		}
	},
	methods: {
		minus(type) {
			// if (type === 'guests' && this.values[type] < 2) {
			//   return
			// }

			if (this.values[type] > 0) {
				this.values[type] -= 1
			}
		},
		plus(type) {
			this.values[type] += 1
		}
	}
}
</script>

<style lang="sass">
.count-guests
  width: 270px
  padding: 15px 20px
  background-color: #FFFFFF
  box-shadow: 0px 9px 36px -7px rgba(0, 0, 0, 0.15)
  border-radius: 11px
  box-sizing: border-box

  &__container
    display: flex
    justify-content: space-between

    &:first-of-type
      padding-bottom: 10px
      margin-bottom: 10px
      border-bottom: 0.5px solid #F5F5F5

  &__text
    margin: 0
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 16px
    letter-spacing: -0.01em
    color: #3B3F47

  &__sub-text
    display: block
    margin-top: 4px
    font-weight: 300
    font-size: 12px
    line-height: 12px
    color: #AFA9A7

  &__values
    display: flex
    justify-content: space-between
    max-width: 105px

  &__btn
    width: 30px
    height: 30px

    &:disabled
      opacity: 0.5

  &__input
    width: 40px
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 14px
    text-align: center
    letter-spacing: -0.01em
    color: #3B3F47
    border: 0
    box-sizing: border-box
    background-color: transparent

    &:hover,
    &:focus,
    &:active
      outline: 0
</style>
